import React from 'react';
import logo from '../images/Samarth jiyo.png'
import logo2 from '../images/logo-2.png';
import './samarth.css';
import { useTranslation } from 'react-i18next';

const Samarth = () => {
  const { t } = useTranslation();


  return (
    <div className="samarth">
      <div className="samarth-column logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="samarth-column-2">
        <h1>{t ('website')}</h1>
      </div>
      <div className="samarth-column logo2">
        <img src={logo2} alt="logo2" />
      </div>
    </div>
  )
};

export default Samarth;