import React from 'react';
import { useTranslation } from 'react-i18next';
import './language.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className='container'>
      <div className='english'>
      <button onClick={() => changeLanguage('en')}>English</button>
      </div>
      <div className='hindi'>
      <button onClick={() => changeLanguage('hi')}>हिन्दी</button>
      </div>
    </div>
  );
};

export default LanguageSwitcher;