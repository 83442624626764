import React, { useState } from "react";
import './textsizechanger.css';

const TextSizeChanger = () => {
  const defaultSize = 16;
  const minSize = 12;
  const maxSize = 24;
  const [textSize, setTextSize] = useState(defaultSize);

  const increaseTextSize = () => {
    if (textSize < maxSize) {
      setTextSize(prevSize => prevSize + 1);
      document.documentElement.style.setProperty('--dynamic-text-size', `${textSize + 2}px`);
    }
  };

  const decreaseTextSize = () => {
    if (textSize > minSize) {
      setTextSize(prevSize => prevSize - 1);
      document.documentElement.style.setProperty('--dynamic-text-size', `${textSize - 2}px`);
    }
  };

  const resetTextSize = () => {
    setTextSize(defaultSize);
    document.documentElement.style.setProperty('--dynamic-text-size', `${defaultSize}px`);
  };

  return (
    <div className="text-size-changer">
      <button onClick={decreaseTextSize} aria-label="Decrease text size" disabled={textSize <= minSize}>
        A-
      </button>
      <button onClick={increaseTextSize} aria-label="Increase text size" disabled={textSize >= maxSize}>
        A+
      </button>
      <button onClick={resetTextSize} aria-label="Reset text size">
        A
      </button>
    </div>
  );
};

export default TextSizeChanger;