import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-column">
                    <h4>Company</h4>
                    <ul>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="#careers">Careers</a></li>
                        <li><a href="#press">Press</a></li>
                        <li><a href="#blog">Blog</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4>Support</h4>
                    <ul>
                        <li><a href="#help">Help Center</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                        <li><a href="#faq">FAQs</a></li>
                        <li><a href="#t&c">Terms & Conditions</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4>Legal</h4>
                    <ul>
                        <li><a href="#privacy">Privacy Policy</a></li>
                        <li><a href="#terms">Terms of Service</a></li>
                        <li><a href="#cookie">Cookie Policy</a></li>
                        <li><a href="#gdpr">GDPR Compliance</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4>Contact Us</h4>
                    <p>Samarth Residential Care Centre
                        International Boys Hostel (BASEMENT)
                        Madhya Marg, Sector 15, CHANDIGARH
                        Ph:- 0172-2783676
                        Email:- drchavanbs@gmail.com</p>
                </div>

                <p className='sign'>Created and maintained by <a href="https://www.linkedin.com/in/keshav-sharma-1352432a6" target="_blank" rel="noopener noreferrer">Keshav Sharma</a></p>

                <p>&copy; {new Date().getFullYear()} Samarth. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
