import React from 'react';
import './header.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();
    return (
        <header>
            <nav>
                <ul>
                    <li><Link to="/">{t('home')}</Link></li>
                    <li><Link to="/about">{t('about')}</Link>
                        <ul className="dropdown">
                            <li><a href="#about1">{t('about')} 1</a></li>
                            <li><a href="#about2">{t('about')} 2</a></li>
                            <li><a href="#about3">{t('about')} 3</a></li>
                        </ul></li>
                    <li><Link to="/facility">{t('facility')}</Link>
                        <ul className="dropdown">
                            <li><a href="#facility1">{t('facility')} 1</a></li>
                            <li><a href="#facility2">{t('facility')} 2</a></li>
                            <li><a href="#facility3">{t('facility')} 3</a></li>
                        </ul></li>
                    <li><Link to="/gallery">{t('gallery')}</Link>
                        <ul className="dropdown">
                            <li><a href="#gallery1">{t('photo')} {t('gallery')}</a></li>
                            <li><a href="#gallery2">{t('video')} {t('gallery')}</a></li>
                            <li><a href="#gallery3">Events</a></li>
                        </ul></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;