import React from 'react';
import './about.css';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    return (
        <section id="about">
            <h2>About Us</h2>
            <p>{t('samarth')}</p>
            <p>{t('samarth-2')}</p>
        </section>
    );
};

export default About;