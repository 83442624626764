import React from 'react'
import Slideshow from './slideshow';
import './gallery.css';

const gallery = () => {
    return (
        <section>
            <Slideshow />
        </section>
    )
}

export default gallery;