import React, { useState } from "react";
import './top.css';
import LanguageSwitcher from "./language";
import TextSizeChanger from "./textsizechanger";

const Top = () => {
    const [bgColor, setBgColor] = useState('#3a7b91');

    return (
        <div>
            <div className="top-cloumn">
                <div className="social-icons" style={{
                    backgroundColor: bgColor
                }}>
                    <p>Select Theme:</p>
                    <button className="blue" onClick={() => setBgColor('#3a7b91')}></button>
                    <button className="brown" onClick={() => setBgColor('#773e0c')}></button>
                    <button className="green" onClick={() => setBgColor('#06644d')}></button>
                    <p>Text Size:</p>
                    <TextSizeChanger />
                    <p>Language:</p>
                    <LanguageSwitcher />
                    <a href="https://www.facebook.com/p/Samarth-jiyo-residential-care-100064495760167/" aria-label="Facebook" className="fab fa-facebook"></a>
                </div>
            </div>
        </div>
    )
};

export default Top;