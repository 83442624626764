import React, { useState, useEffect, useRef, useCallback } from 'react';
import './slideshow.css';
import img1 from '../images/IMG_3362.jpeg';
import img2 from '../images/IMG_3389.jpeg';
import img3 from '../images/IMG_3418.jpeg';
import img4 from '../images/IMG_3423.jpeg';

const Slideshow = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const slides = useRef([]);
    const slideWrapper = useRef(null);
    const totalSlides = 4;

    const showSlide = (index) => {
        if (slideWrapper.current) {
            slideWrapper.current.style.transform = `translateX(-${index * 100}%)`;
        }

        if (slides.current) {
            slides.current.forEach((slide) => {
                if (slide) {
                    slide.classList.remove('active');
                }
            });

            const currentSlide = slides.current[index];
            if (currentSlide) {
                currentSlide.classList.add('active');
            }
        }
    };

    const changeSlide = useCallback((n) => {
        const newIndex = (slideIndex + n + totalSlides) % totalSlides;
        setSlideIndex(newIndex);
    }, [slideIndex, totalSlides]);

    useEffect(() => {
        slides.current = document.querySelectorAll('.slide');
        slideWrapper.current = document.querySelector('.slide-wrapper');
        showSlide(slideIndex);

        const intervalId = setInterval(() => {
            changeSlide(1);
        }, 5000);

        return () => clearInterval(intervalId);
    }, [slideIndex, changeSlide]);

    return (
        <div className="slideshow-container">
            <div className="slide-wrapper">
                <div className="slide">
                    <img src={img1} alt="A beautiful landscape" />
                </div>
                <div className="slide">
                    <img src={img2} alt="A modern building exterior" />
                </div>
                <div className="slide">
                    <img src={img3} alt="A city skyline at sunset" />
                </div>
                <div className="slide">
                    <img src={img4} alt="A vibrant garden with flowers" />
                </div>
            </div>
        </div>
    );
};

export default Slideshow;