import React from 'react';
import './facility.css';

const Facility = () => {
    return (
        <section className='facility'>
            <h2>Facilities</h2>
            <p>SAMARTH Residential Care Centre Project is being implemented at
                the International Boys Hostel (Basement) Madhya Marg, Sector-15, Chandigarh SAMARTH has following
                facilities:-
                <ul>
                    <li>Shared accommodation (Two students in one room)</li>
                    <li>Separate accommodation for boys and girls.</li>
                    <li>Kitchen with dining hall providing nutritious and balance diet.</li>
                    <li>Laundry facilities.</li>
                    <li>T.V with cable services.</li>
                    <li>Dance and music.</li>
                    <li>Celebration of festivals and birthdays.</li>
                    <li>Adequate toilets facilities for boys and girls.</li>
                    <li>Indoor and outdoor games.</li>
                    <li>Regular picnic outings.</li>
                    <li>For special education and vocational training. the residents of Samarth are being trained specialized and exprienced staff of GRIID during day time.</li>
                </ul>
            </p>
        </section>
    )
}

export default Facility