import React from 'react';
import './home.css';
import Slideshow from './slideshow.jsx';

const Home = () => {
    return (
        <section id='home'>
            <Slideshow />
        </section>
    );
};

export default Home;
